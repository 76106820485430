define("ember-svg-jar/inlined/future", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_4879_2289)\" fill=\"#70E1FF\"><path d=\"M6.975 4.055l-.841-3.3c-.087-.34-.58-.34-.667 0l-.841 3.3c-.123.483-.508.86-1 .98L.26 5.862a.335.335 0 000 .654l3.365.825c.493.12.878.498 1 .98l.842 3.3c.087.34.58.34.667 0l.841-3.3c.123-.482.508-.86 1-.98l3.366-.825a.335.335 0 000-.654l-3.365-.825a1.362 1.362 0 01-1-.98zM13.484 13.085l-.362-1.42c-.073-.287-.49-.287-.562 0l-.362 1.42a1.15 1.15 0 01-.844.827l-1.448.355a.283.283 0 000 .552l1.448.355c.415.102.74.42.844.828l.362 1.419c.073.287.49.287.562 0l.362-1.42a1.15 1.15 0 01.844-.827l1.448-.355a.283.283 0 000-.552l-1.448-.355a1.15 1.15 0 01-.844-.827z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.944 27.142c.142-.56.647-.952 1.225-.952h1.046a12.58 12.58 0 01-2.687-7.791c0-6.978 5.656-12.635 12.634-12.635 6.978 0 12.635 5.657 12.635 12.635a12.58 12.58 0 01-2.688 7.79h1.047c.578 0 1.082.393 1.224.953l1.07 4.212c.203.799-.401 1.574-1.225 1.574H7.1a1.263 1.263 0 01-1.224-1.574l1.069-4.212zm7.017-15.17l-.628-2.465c-.127-.498-.85-.498-.976 0l-.628 2.464a1.995 1.995 0 01-1.465 1.437l-2.513.616a.491.491 0 000 .958l2.513.616c.721.177 1.284.73 1.465 1.437l.628 2.464c.127.498.85.498.976 0l.628-2.464c.18-.708.744-1.26 1.465-1.437l2.513-.616a.491.491 0 000-.958l-2.513-.616a1.994 1.994 0 01-1.465-1.437z\"/><path d=\"M33.127 6.4l.362 1.42c.104.408.428.726.844.828l1.447.355a.283.283 0 010 .552l-1.447.355c-.416.101-.74.42-.844.827l-.362 1.42c-.073.287-.49.287-.563 0l-.362-1.42a1.15 1.15 0 00-.844-.827l-1.448-.355a.283.283 0 010-.552l1.448-.355c.416-.102.74-.42.844-.828l.362-1.42c.073-.286.49-.286.563 0z\"/></g><defs><clipPath id=\"clip0_4879_2289\"><path fill=\"#fff\" transform=\"translate(0 .5)\" d=\"M0 0h36v36H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "36",
      "height": "37",
      "viewBox": "0 0 36 37",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});