define("ember-svg-jar/inlined/social-threads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.827 12.977a9.106 9.106 0 00-.35-.166c-.205-3.983-2.274-6.262-5.75-6.286h-.046c-2.079 0-3.807.933-4.871 2.63l1.91 1.379c.796-1.268 2.043-1.538 2.961-1.538h.032c1.144.007 2.008.357 2.567 1.04.406.496.678 1.182.813 2.048a13.914 13.914 0 00-3.284-.166c-3.304.2-5.428 2.226-5.285 5.041.072 1.428.749 2.657 1.905 3.46.977.678 2.236 1.01 3.544.934 1.728-.1 3.083-.792 4.029-2.06.718-.962 1.172-2.21 1.373-3.781.823.522 1.433 1.21 1.77 2.036.574 1.405.607 3.715-1.185 5.597-1.57 1.649-3.457 2.362-6.308 2.384-3.164-.024-5.556-1.091-7.112-3.17-1.456-1.947-2.209-4.76-2.237-8.359.028-3.6.78-6.412 2.237-8.359 1.556-2.079 3.948-3.146 7.112-3.17 3.186.024 5.62 1.096 7.235 3.186.792 1.024 1.39 2.313 1.783 3.815l2.24-.628c-.478-1.85-1.229-3.443-2.25-4.764C18.59 1.4 15.56.028 11.66 0h-.016c-3.894.028-6.888 1.406-8.9 4.095C.954 6.488.031 9.817 0 13.99v.02c.031 4.173.954 7.502 2.744 9.895 2.012 2.689 5.006 4.067 8.9 4.095h.015c3.462-.025 5.902-.978 7.912-3.09a7.864 7.864 0 001.684-8.352c-.621-1.524-1.807-2.763-3.428-3.58zm-5.977 5.91c-1.448.085-2.953-.598-3.027-2.062-.055-1.085.735-2.296 3.115-2.44.273-.017.54-.025.803-.025.865 0 1.674.088 2.41.257-.275 3.603-1.884 4.188-3.301 4.27z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "fill": "currentColor",
      "viewBox": "0 0 28 28"
    }
  };
});