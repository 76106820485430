define("ember-svg-jar/inlined/hashtag-trend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.584 1.696c.704.088 1.204.73 1.116 1.435l-.147 1.18h2.658l.187-1.5a1.286 1.286 0 112.552.32l-.148 1.18h.84a1.286 1.286 0 010 2.57h-1.16l-.336 2.68h1.497a1.286 1.286 0 010 2.571h-1.818l-.188 1.499a1.286 1.286 0 11-2.551-.32l.147-1.179h-2.658l-.188 1.499a1.286 1.286 0 11-2.551-.32l.148-1.179h-.841a1.286 1.286 0 010-2.571h1.162l.335-2.68h-1.497a1.286 1.286 0 110-2.57h1.818l.188-1.5a1.286 1.286 0 011.435-1.115zm.312 7.865h2.659l.335-2.68H27.23l-.335 2.68z\" fill=\"#70E1FF\"/><path d=\"M12.698 16.011l-2.637 8.378h-7.49a1.446 1.446 0 010-2.893H7.94l2.854-9.07c.633-2.012 3.509-1.926 4.02.12l3.585 14.34 4.12-9.064c.747-1.643 3.085-1.63 3.813.021l1.612 3.653h5.486a1.446 1.446 0 010 2.893h-7.372l-1.648-3.736-4.33 9.526c-.819 1.8-3.45 1.561-3.929-.358l-3.452-13.81z\" fill=\"#70E1FF\"/>",
    "attrs": {
      "width": "36",
      "height": "37",
      "viewBox": "0 0 36 37",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});