define("ember-svg-jar/inlined/hot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.442 26c-1.843-3.606-.86-5.672.555-7.618 1.551-2.132 1.951-4.242 1.951-4.242s1.22 1.49.732 3.821c2.154-2.254 2.56-5.846 2.235-7.222 4.868 3.2 6.948 10.127 4.145 15.261 14.91-7.936 3.707-19.808 1.757-21.146.65 1.337.773 3.602-.54 4.7C17.054 1.625 11.557 0 11.557 0c.65 4.09-2.356 8.56-5.255 11.902-.102-1.631-.21-2.756-1.12-4.316-.206 2.962-2.612 5.376-3.264 8.344-.883 4.018.661 6.961 6.526 10.07h-.002z\" fill=\"#007009\"/>",
    "attrs": {
      "width": "27",
      "height": "26",
      "viewBox": "0 0 27 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});